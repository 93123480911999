import { Keypair as GenKeyPair } from "../../features/api/voting";
function getMessageToSign(chainID, roundID) {
  if (chainID === 11155111) {
    return `Sign this message to get your public key for MACI voting on Allo for the round with address ${roundID} on chain ${chainID}`;
  }
  return `Sign this message to create your unique MACI KeyPair for rounId ${roundID} & chainId ${chainID} . This KeyPair is essential for securely encrypting and decrypting your donations. Think of this process like generating a seed phrase, with a new one created for each round.`;
}
export const getMACIKeys = async _ref => {
  let {
    chainID,
    roundID,
    walletAddress,
    walletClient
  } = _ref;
  const MACIKeys = localStorage.getItem("MACIKeys");
  const address = walletAddress.toLowerCase();
  let signatureSeeds;
  try {
    signatureSeeds = JSON.parse(MACIKeys ? MACIKeys : "{}");
  } catch (e) {
    console.error("Failed to parse MACIKeys from localStorage:", e);
    signatureSeeds = {};
  }

  // Ensure the structure exists
  if (typeof signatureSeeds.rounds !== "object" || signatureSeeds.rounds === null) {
    signatureSeeds.rounds = {};
  }
  if (typeof signatureSeeds.rounds[chainID] !== "object" || signatureSeeds.rounds[chainID] === null) {
    signatureSeeds.rounds[chainID] = {};
  }
  if (typeof signatureSeeds.rounds[chainID][roundID] !== "object" || signatureSeeds.rounds[chainID][roundID] === null) {
    signatureSeeds.rounds[chainID][roundID] = {};
  }
  let signature = signatureSeeds.rounds[chainID][roundID][address];
  if (!signature) {
    try {
      signature = await walletClient.signMessage({
        message: getMessageToSign(chainID, roundID)
      });
    } catch (e) {
      console.error("Failed to sign message:", e);
    }

    // Ensure the nested structure exists before assigning the new signature
    if (!signatureSeeds.rounds[chainID][roundID]) {
      signatureSeeds.rounds[chainID][roundID] = {};
    }
    signatureSeeds.rounds[chainID][roundID][address] = signature;
    localStorage.setItem("MACIKeys", JSON.stringify(signatureSeeds));
  }
  return signature;
};
export const signAndStoreSignatures = async _ref2 => {
  let {
    pairs,
    walletClient,
    address
  } = _ref2;
  for (const pair of pairs) {
    const {
      chainId,
      roundId
    } = pair;
    await getMACIKeys({
      chainID: chainId,
      roundID: roundId,
      walletAddress: address,
      walletClient: walletClient
    });
  }
};
export const getMACIKey = _ref3 => {
  let {
    chainID,
    roundID,
    walletAddress
  } = _ref3;
  const MACIKeys = localStorage.getItem("MACIKeys");
  const address = walletAddress.toLowerCase();
  let signatureSeeds;
  try {
    signatureSeeds = JSON.parse(MACIKeys ? MACIKeys : "{}");
  } catch (e) {
    console.error("Failed to parse MACIKeys from localStorage:", e);
    signatureSeeds = {};
  }

  // Ensure the structure exists
  if (typeof signatureSeeds.rounds !== "object" || signatureSeeds.rounds === null) {
    signatureSeeds.rounds = {};
  }
  if (typeof signatureSeeds.rounds[chainID] !== "object" || signatureSeeds.rounds[chainID] === null) {
    signatureSeeds.rounds[chainID] = {};
  }
  if (typeof signatureSeeds.rounds[chainID][roundID] !== "object" || signatureSeeds.rounds[chainID][roundID] === null) {
    signatureSeeds.rounds[chainID][roundID] = {};
  }
  const signature = signatureSeeds.rounds[chainID][roundID][address];
  if (!signature) {
    return;
  }
  return signature;
};
export const generatePubKey = async (walletClient, roundID, chainID) => {
  const MACIKeys = localStorage.getItem("MACIKeys");
  const address = walletClient.account.address.toLowerCase();
  let signatureSeeds;
  try {
    signatureSeeds = JSON.parse(MACIKeys ? MACIKeys : "{}");
  } catch (e) {
    signatureSeeds = {};
  }

  // Ensure the structure exists
  if (typeof signatureSeeds.rounds !== "object" || signatureSeeds.rounds === null) {
    signatureSeeds.rounds = {};
  }
  if (typeof signatureSeeds.rounds[chainID] !== "object" || signatureSeeds.rounds[chainID] === null) {
    signatureSeeds.rounds[chainID] = {};
  }
  if (typeof signatureSeeds.rounds[chainID][roundID] !== "object" || signatureSeeds.rounds[chainID][roundID] === null) {
    signatureSeeds.rounds[chainID][roundID] = {};
  }
  let signature = signatureSeeds.rounds[chainID][roundID][address];
  if (!signature) {
    signature = await walletClient.signMessage({
      message: getMessageToSign(Number(chainID), roundID)
    });

    // Ensure the nested structure exists before assigning the new signature
    if (!signatureSeeds.rounds[chainID][roundID]) {
      signatureSeeds.rounds[chainID][roundID] = {};
    }
    signatureSeeds.rounds[chainID][roundID][address] = signature;
    localStorage.setItem("MACIKeys", JSON.stringify(signatureSeeds));
  }
  const getUserPubKey = GenKeyPair.createFromSeed(signature);
  return getUserPubKey;
};
export const generatePubKeyWithSeed = seed => {
  const getUserPubKey = GenKeyPair.createFromSeed(seed);
  return getUserPubKey;
};