import { booleanToBigInt, hexToBigInt, numberToBigInt, uuidToBigInt } from "@pcd/util";
import { sha256 } from "js-sha256";
function convertStringArrayToBigIntArray(arr) {
  return arr.map(x => BigInt(x));
}

/**
 * Encoding of -1 in a Baby Jubjub field element (as p-1).
 */
export const BABY_JUB_NEGATIVE_ONE = BigInt("21888242871839275222246405745257275088548364400416034343698204186575808495616");
export const fieldsToReveal = {
  revealAttendeeEmail: true,
  revealEventId: true,
  revealProductId: true
};
export const ZUAUTH_CONFIG = [{
  pcdType: "eddsa-ticket-pcd",
  publicKey: ["05e0c4e8517758da3a26c80310ff2fe65b9f85d89dfc9c80e6d0b6477f88173e", "29ae64b615383a0ebb1bc37b3a642d82d37545f0f5b1444330300e4c4eedba3f"],
  eventId: "91312aa1-5f74-4264-bdeb-f4a3ddb8670c",
  eventName: "Zuconnect23",
  productId: "cc9e3650-c29b-4629-b275-6b34fc70b2f9",
  productName: ""
}];

/**
 * Max supported size of validEventIds field in ZKEdDSAEventTicketPCDArgs.
 */
export const VALID_EVENT_IDS_MAX_LEN = 20;
export function generateSnarkMessageHash(signal) {
  // right shift to fit into a field element, which is 254 bits long
  // shift by 8 ensures we have a 253 bit element
  return BigInt("0x" + sha256(signal)) >> BigInt(8);
}
export const STATIC_TICKET_PCD_NULLIFIER = generateSnarkMessageHash("dummy-nullifier-for-eddsa-event-ticket-pcds");
export function snarkInputForValidEventIds(validEventIds) {
  if (validEventIds === undefined) {
    validEventIds = [];
  }
  if (validEventIds.length > VALID_EVENT_IDS_MAX_LEN) {
    throw new Error("validEventIds for a ZKEdDSAEventTicketPCD can have up to 100 entries.  " + validEventIds.length + " given.");
  }
  const snarkIds = new Array(VALID_EVENT_IDS_MAX_LEN);
  let i = 0;
  for (const validId of validEventIds) {
    snarkIds[i] = uuidToBigInt(validId).toString();
    ++i;
  }
  for (; i < VALID_EVENT_IDS_MAX_LEN; ++i) {
    snarkIds[i] = BABY_JUB_NEGATIVE_ONE.toString();
  }
  return snarkIds;
}
export function publicSignalsFromClaim(claim) {
  var _claim$externalNullif;
  const t = claim.partialTicket;
  const ret = [];
  const negOne = BABY_JUB_NEGATIVE_ONE.toString();

  // Outputs appear in public signals first
  ret.push(t.ticketId === undefined ? negOne : uuidToBigInt(t.ticketId).toString());
  ret.push(t.eventId === undefined ? negOne : uuidToBigInt(t.eventId).toString());
  ret.push(t.productId === undefined ? negOne : uuidToBigInt(t.productId).toString());
  ret.push(t.timestampConsumed === undefined ? negOne : t.timestampConsumed.toString());
  ret.push(t.timestampSigned === undefined ? negOne : t.timestampSigned.toString());
  ret.push(t.attendeeSemaphoreId || negOne);
  ret.push(t.isConsumed === undefined ? negOne : booleanToBigInt(t.isConsumed).toString());
  ret.push(t.isRevoked === undefined ? negOne : booleanToBigInt(t.isRevoked).toString());
  ret.push(t.ticketCategory === undefined ? negOne : numberToBigInt(t.ticketCategory).toString());
  ret.push(t.attendeeEmail === undefined ? negOne : generateSnarkMessageHash(t.attendeeEmail).toString());
  ret.push(t.attendeeName === undefined ? negOne : generateSnarkMessageHash(t.attendeeName).toString());

  // Placeholder for reserved field
  ret.push(negOne);
  ret.push(claim.nullifierHash || negOne);

  // Public inputs appear in public signals in declaration order
  ret.push(hexToBigInt(claim.signer[0]).toString());
  ret.push(hexToBigInt(claim.signer[1]).toString());
  for (const eventId of snarkInputForValidEventIds(claim.validEventIds)) {
    ret.push(eventId);
  }
  ret.push(claim.validEventIds !== undefined ? "1" : "0"); // checkValidEventIds

  ret.push(((_claim$externalNullif = claim.externalNullifier) === null || _claim$externalNullif === void 0 ? void 0 : _claim$externalNullif.toString()) || STATIC_TICKET_PCD_NULLIFIER.toString());
  ret.push(claim.watermark);
  return ret;
}

// uint[2] calldata _pA, uint[2][2] calldata _pB, uint[2] calldata _pC, uint[38] calldata _pubSignals
export const generateWitness = pcd => {
  const _pA = pcd.proof.pi_a.slice(0, 2);
  const _pB = [pcd.proof.pi_b[0].slice(0).reverse(), pcd.proof.pi_b[1].slice(0).reverse()];
  const _pC = pcd.proof.pi_c.slice(0, 2);
  const _pubSignals = convertStringArrayToBigIntArray(publicSignalsFromClaim(pcd.claim));
  return {
    _pA,
    _pB,
    _pC,
    _pubSignals
  };
};