export const getVoteIdMap = async (applications, dataLayer) => {
  const voteIdMap = {};
  for (const app of applications) {
    var _app$anchorAddress;
    const chainID = Number(app.chainId);
    const ID = await dataLayer.getVoteOptionIndexByChainIdAndRoundId({
      chainId: chainID,
      roundId: app.roundId,
      recipientId: (_app$anchorAddress = app.anchorAddress) !== null && _app$anchorAddress !== void 0 ? _app$anchorAddress : ""
    });
    const voteOption = ID === null || ID === void 0 ? void 0 : ID.votingIndexOptions[0].optionIndex;
    const chainId = Number(app.chainId);

    // Initialize nested objects if they don't exist
    if (!voteIdMap[chainId]) {
      voteIdMap[chainId] = {};
    }
    if (!voteIdMap[chainId][app.roundId]) {
      voteIdMap[chainId][app.roundId] = {};
    }

    // Store the ID with the maximum nonce found
    voteIdMap[chainId][app.roundId][app.id] = {
      id: voteOption,
      maxNonce: undefined,
      newVoteWeight: undefined,
      timestamp: undefined,
      transactionHash: undefined,
      chainId: chainId,
      roundId: app.roundId
    };
  }
  return voteIdMap;
};