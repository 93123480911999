import { getVoteIdMap } from "./projectsMatching";
import { createCartProjectFromApplication } from "../discovery/ExploreProjectsPage";
import { getMACIKey } from "./keys";
import { formatEther } from "viem";
import { dateToEthereumTimestamp } from "common";
function translateApplicationToContribution(application, amount, price, walletAddress, timestamp, blockNumber, transactionHash) {
  var _application$metadata, _application$project;
  return {
    id: application.id,
    chainId: parseInt(application.chainId, 10),
    projectId: application.projectId,
    roundId: application.roundId,
    recipientAddress: application === null || application === void 0 || (_application$metadata = application.metadata) === null || _application$metadata === void 0 || (_application$metadata = _application$metadata.application) === null || _application$metadata === void 0 ? void 0 : _application$metadata.recipient,
    applicationId: application === null || application === void 0 ? void 0 : application.id,
    tokenAddress: application.round.matchTokenAddress,
    donorAddress: walletAddress,
    // This should be replaced with the actual donor address if available
    amount: (Number(amount) * 10 ** 18).toString(),
    // This should be replaced with the actual donation amount if available
    amountInUsd: Number((Number(price) * Number(amount)).toFixed(2)),
    // This should be replaced with the actual donation amount in USD if available
    transactionHash: transactionHash,
    // This should be replaced with the actual transaction hash if available
    blockNumber: blockNumber,
    // This should be replaced with the actual block number if available
    round: {
      roundMetadata: application.round.roundMetadata,
      donationsStartTime: application.round.donationsStartTime,
      donationsEndTime: application.round.donationsEndTime
    },
    application: {
      project: {
        name: (_application$project = application.project) === null || _application$project === void 0 || (_application$project = _application$project.metadata) === null || _application$project === void 0 ? void 0 : _application$project.title
      }
    },
    timestamp: dateToEthereumTimestamp(timestamp ? new Date(timestamp) : new Date())
  };
}
async function getApplicationsByVoteOptionIndex(maciContributions, applications, votes, voteIdMap) {
  return applications.map(app => {
    var _maciContributions$en, _maciContributions$en2, _maciContributions$en3, _maciContributions$en4;
    const voteInfo = voteIdMap[Number(app.chainId)][app.roundId][app.id];
    const matchingVotes = votes.filter(vote => voteInfo.id.toString() === vote.voteOptionIndex.toString());
    let maxNonceVote;
    // Find the vote with the maximum nonce
    if (matchingVotes.length === 0) {
      return {
        ...app,
        applicationId: voteInfo.id.toString(),
        newVoteWeight: "0",
        timestamp: undefined,
        transactionHash: undefined
      };
    } else if (matchingVotes.length === 1) {
      maxNonceVote = matchingVotes[0];
    } else {
      maxNonceVote = matchingVotes.reduce((maxVote, currentVote) => maxVote === undefined || currentVote.nonce > maxVote.nonce ? currentVote : maxVote);
    }
    // Update the maxNonce in the voteIdMap
    voteInfo.maxNonce = maxNonceVote.nonce;
    const matchedVote = votes.find(vote => voteInfo.id.toString() === vote.voteOptionIndex.toString() && vote.nonce === voteInfo.maxNonce);
    const voteWeight = matchedVote && matchedVote.newVoteWeight !== 0n ? formatEther(matchedVote.newVoteWeight * matchedVote.newVoteWeight * 10n ** 13n).toString() : matchedVote && matchedVote.newVoteWeight === 0n ? undefined : "0";
    return {
      ...app,
      applicationId: voteInfo.id.toString(),
      newVoteWeight: voteWeight,
      timestamp: (_maciContributions$en = maciContributions === null || maciContributions === void 0 || (_maciContributions$en2 = maciContributions.encrypted) === null || _maciContributions$en2 === void 0 ? void 0 : _maciContributions$en2.timestamp) !== null && _maciContributions$en !== void 0 ? _maciContributions$en : undefined,
      transactionHash: (_maciContributions$en3 = maciContributions === null || maciContributions === void 0 || (_maciContributions$en4 = maciContributions.encrypted) === null || _maciContributions$en4 === void 0 ? void 0 : _maciContributions$en4.transactionHash) !== null && _maciContributions$en3 !== void 0 ? _maciContributions$en3 : undefined
    };
  }).filter(app => {
    // Exclude contributed projects with newVoteWeight === "0"
    return app.newVoteWeight !== undefined && app.newVoteWeight !== "0";
  });
}
const getContributed = async (dataLayer, groupedMaciContributions, groupedDecryptedContributions, applications) => {
  const contributedApplications = [];
  for (const chainID of Object.keys(groupedMaciContributions || {})) {
    const chainId = Number(chainID);
    for (const roundID of Object.keys((_ref = groupedMaciContributions && groupedMaciContributions[chainId]) !== null && _ref !== void 0 ? _ref : {})) {
      var _ref, _groupedDecryptedCont, _groupedMaciContribut, _applications$chainId;
      const decryptedMessages = groupedDecryptedContributions ? ((_groupedDecryptedCont = groupedDecryptedContributions[chainId]) === null || _groupedDecryptedCont === void 0 ? void 0 : _groupedDecryptedCont[roundID]) || [] : [];
      const maciContributionsForChainRound = groupedMaciContributions ? (_groupedMaciContribut = groupedMaciContributions[chainId]) === null || _groupedMaciContribut === void 0 ? void 0 : _groupedMaciContribut[roundID] : undefined;
      const applicationsForChainRound = applications ? ((_applications$chainId = applications[chainId]) === null || _applications$chainId === void 0 ? void 0 : _applications$chainId[roundID]) || [] : [];
      const voteIdMap = await getVoteIdMap(applicationsForChainRound, dataLayer);
      const contributed = await getApplicationsByVoteOptionIndex(maciContributionsForChainRound, applicationsForChainRound, decryptedMessages, voteIdMap);
      contributedApplications.push(...contributed);
    }
  }
  return contributedApplications;
};
export const setContributed = async (projects, walletAddress, dataLayer, setUserCart, applications, groupedMaciContributions, groupedDecryptedContributions) => {
  const contributedTo = await getContributed(dataLayer, groupedMaciContributions, groupedDecryptedContributions, applications);
  const applicationRefs = getApplicationRefs(projects, applications);
  dataLayer.getApprovedApplicationsByExpandedRefs(applicationRefs).then(applications => {
    const updatedProjects = applications.flatMap(application => {
      var _contribution$newVote, _contribution$newVote2;
      const contribution = contributedTo.find(app => {
        return application.roundApplicationId.toLowerCase() === app.id.toLowerCase() && application.chainId === Number(app.chainId) && application.roundId === app.roundId;
      });
      const newProject = createCartProjectFromApplication(application);
      return {
        ...newProject,
        amount: (_contribution$newVote = contribution === null || contribution === void 0 || (_contribution$newVote2 = contribution.newVoteWeight) === null || _contribution$newVote2 === void 0 ? void 0 : _contribution$newVote2.toString()) !== null && _contribution$newVote !== void 0 ? _contribution$newVote : "0"
      };
    });

    // Retain new projects that haven't been contributed to
    const newProjects = projects.filter(project => !contributedTo.some(contrib => {
      var _project$anchorAddres;
      return ((_project$anchorAddres = project.anchorAddress) === null || _project$anchorAddres === void 0 ? void 0 : _project$anchorAddres.toString()) === contrib.id && project.chainId === Number(contrib.chainId) && project.roundId === contrib.roundId;
    }));

    // Combine new projects with updated ones, excluding contributed projects with newVoteWeight === "0"
    setUserCart([...updatedProjects.filter(p => p.amount !== "0"), ...newProjects], walletAddress);
  }).catch(error => {
    console.error("Error fetching applications in cart", error);
  });
};
function getApplicationRefs(projects, applications) {
  const applicationRefs = projects.map(project => {
    var _project$anchorAddres2, _project$anchorAddres3;
    return {
      chainId: project.chainId,
      roundId: project.roundId,
      id: (_project$anchorAddres2 = (_project$anchorAddres3 = project.anchorAddress) === null || _project$anchorAddres3 === void 0 ? void 0 : _project$anchorAddres3.toString()) !== null && _project$anchorAddres2 !== void 0 ? _project$anchorAddres2 : ""
    };
  });
  if (applications) {
    for (const chainId of Object.keys(applications)) {
      const chainID = Number(chainId);
      for (const roundId of Object.keys(applications[chainID])) {
        applications[chainID][roundId].forEach(project => {
          if (!applicationRefs.some(app => app.chainId === chainID && app.roundId === roundId && app.id === project.id.toString())) {
            applicationRefs.push({
              chainId: chainID,
              roundId: roundId,
              id: project.id.toString()
            });
          }
        });
      }
    }
  }
  return applicationRefs;
}
export const getDonationHistory = async (dataLayer, walletAddress, applications, groupedMaciContributions, groupedDecryptedContributions) => {
  const contributedTo = await getContributed(dataLayer, groupedMaciContributions, groupedDecryptedContributions, applications);
  const contributions = contributedTo.map(app => {
    var _app$newVoteWeight, _app$transactionHash;
    return translateApplicationToContribution(app, (_app$newVoteWeight = app.newVoteWeight) !== null && _app$newVoteWeight !== void 0 ? _app$newVoteWeight : "0", app.newVoteWeight, walletAddress, app.timestamp,
    // Mock timestamp
    123456, // Mock block number
    (_app$transactionHash = app.transactionHash) !== null && _app$transactionHash !== void 0 ? _app$transactionHash : "0xabcdef" // Mock transaction hash
    );
  });
  return contributions;
};
export const areSignaturesPresent = (maciContributions, walletAddress) => {
  if (!walletAddress) return false;
  if (!maciContributions) return true;
  for (const chainId in maciContributions) {
    for (const roundId in maciContributions[chainId]) {
      const signature = getMACIKey({
        chainID: Number(chainId),
        roundID: roundId,
        walletAddress
      });
      if (!signature) {
        return false;
      }
    }
  }
  return true;
};