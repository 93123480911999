const collections = [{
  cid: "bafkreidobkgrbrw7mex556jnku4xlony6jvyhepc6yqpzzymasupnuvdi4",
  author: "BitcoinLouie",
  name: "Solo Staking",
  numberOfProjects: 9,
  description: "This collection showcases BitcoinLouie's Solo Staking Collection"
}, {
  cid: "bafkreibp2yzwyj6m2fcgjqp7k6fikj3rr7ew3ceytrnr2zgi6dql2oiiry",
  author: "Kevin Weaver",
  name: "Regen Builders",
  numberOfProjects: 20,
  description: "Kevin's bento assortment of public goods spanning apps, web3 infra, dev tools I use daily and climate initiatives that pair well."
}, {
  cid: "bafkreifk3ejfp3j6eanuvvoqmp2bgyieuq67eh4kqpuxebegshaqaghu5e",
  author: "ThankArb",
  name: "Bring ARB Home ",
  numberOfProjects: 22,
  description: "Think ARB is cool, but never felt like it would really work for you? Take a look at these rounds on Abriturum that you can use to impact your community close to home."
}, {
  cid: "bafkreicneb6yinsk3zwcntohxklo3gcztosj5a2g72sr2dpqlawlcyvpli",
  author: "buidlbox",
  name: "buidl & shill sesh ",
  numberOfProjects: 13,
  description: "We've rounded up all the projects from our recent buidl & shill spaces for #GG20 in a collection"
}, {
  cid: "bafkreihmuhsrdh62kjor5472dsgahhea3ltj33tffhr2cnc5bxae3qetou",
  author: "Benjamin Life",
  name: "Regen Civics",
  numberOfProjects: 25,
  description: "Regen Civics is my curation of civic innovators in the @climate_program round on @gitcoin."
}, {
  cid: "bafkreiffs6li5kwipwf6m4dgwbul3lf5mg766fujks72vm4crdebgybrme",
  author: "Coleen Chase",
  name: "Climate Projects Making Real-World Impact in Rural Africa",
  numberOfProjects: 25,
  description: "Check out my collection of Climate Projects making real-world impact in rural Africa including 2 projects for dMRV."
}, {
  cid: "bafkreibmhp2c2wc7ppbdb3rlgql2xkaxuevfmbdjze2p5svoinrpfb4jim",
  author: "Carl Cervone",
  name: "Carl Cervone's high impact projects",
  numberOfProjects: 100,
  description: "Carl Cervone's favorite OSS and non-OSS projects."
}, {
  cid: "bafkreia7eablt2gbn4afqmo5z5p4gjiiymn3yhpwlmkkhiaotwqwfuidmq",
  author: "Trent Van Epps",
  name: "Trent Van Epps favorite projects",
  numberOfProjects: 17,
  description: "Trents favorite ecosystem projects."
}, {
  cid: "bafkreibmljr4sgcbopmj7z62pj55a4e2krc5hfrjyynj6urqugbhzyyrm4",
  author: "Jon Ellison",
  name: "ReFi DAO & Local Nodes",
  numberOfProjects: 17,
  description: "List of ReFi DAO & Local Nodes."
}, {
  cid: "bafkreic3it6ka4g6e3n2nplo2bmo34tn4nw2drrmkqmjifrespvwzg5ydu",
  author: "David mortech ",
  name: "Africa web3 Changemakers",
  numberOfProjects: 29,
  description: "Africa web3 Changemakers in Gitcoin GG20 QF rounds take a look n support."
}, {
  cid: "bafkreiaqclu2z3tlhhyux3mqgux3y5lfnmgi4end2a4itw25slm2fba4ba",
  author: "Piraox",
  name: "The Future",
  numberOfProjects: 7,
  description: "Projects I think are the future"
}, {
  cid: "bafkreifz2wakvsiw5o6dcinqn7vhgcun2t4wxl7rm22s5g2jsfznmwnnsi",
  author: "Tarah Stafford",
  name: "Women Founders",
  numberOfProjects: 15,
  description: "Womyn Founders GG20 - 15 impactful Climate/ReFi/Impact projects led and co-led by women #GG20"
}];
export default collections;